var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contentView" },
    [
      _c("div", { staticClass: "navbar" }, [_c("navbar")], 1),
      _c("div", { staticClass: "cnt" }),
      _c(
        "draggable",
        {
          staticClass: "contentViewdraggable",
          style: [{ background: _vm.background }],
          attrs: {
            group: "itxst",
            ghostClass: "ghost",
            chosenClass: "chosen",
            selector: "selector",
            animation: 500,
            sort: true,
          },
          model: {
            value: _vm.contentlist,
            callback: function ($$v) {
              _vm.contentlist = $$v
            },
            expression: "contentlist",
          },
        },
        _vm._l(_vm.contentlist, function (item, index) {
          return _c(
            "div",
            {
              key: item.fieldId,
              class:
                item.fieldId == _vm.selectedId
                  ? "componentborderactive componentborder"
                  : "componentborder",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.onChooseArea(item)
                },
              },
            },
            [
              _c(
                item.name,
                _vm._b(
                  {
                    tag: "component",
                    attrs: { quality: item.configuration.quality },
                  },
                  "component",
                  item,
                  false
                )
              ),
              item.fieldId == _vm.selectedId
                ? _c(
                    "div",
                    {
                      staticClass: "componentbtn",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.deleteclcik(item, index)
                        },
                      },
                    },
                    [
                      _vm._v(" 删除 "),
                      _c("i", { staticClass: "el-icon-circle-close" }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }